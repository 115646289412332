'use strict';

angular.module('meanApp')
  .controller('client-files-controller', function ($scope, Auth, FileSaver, DataM, $state, $stateParams,$http) {

    $scope.showDeleteButton = false;
    $scope.isAdmin = false;

    DataM.checkPermission({
      name: "downloadClientJSON"
    }).then(function (data) {
      $scope.isAdmin = data.hasPermission;
      $scope.showDeleteButton = data.hasPermission;
    }).catch(function (err) {
      console.log(err);
    });

    $scope.surveyComplete = false;
    $scope.surveyCompletedAt = new Date();
    $scope.fileContents = [];
    $scope.state = $state.current;
    $scope.params = $stateParams;
    $scope.loadingMessage = "";
    $scope.showSpinner = true;
    $scope.showDownloadDialog = false;
    $scope.errorDialog = false;
    $scope.errorMessage = '';

    DataM.getSummary().then(function (data) {
      $scope.clients = data.clientSummary.personalInfo;
      $scope.clientId = data.clientSummary.uid;
      /*Set full names for ownership*/
      $scope.showSpinner = false;
      $scope.client1FullName = $scope.clients.client1.fName + " " + $scope.clients.client1.lName;
      $scope.openClientDocTable();
      

    }).catch(function (err) {
      $scope.showSpinner = false;

      $scope.errorDialog = true;
      $scope.errorMessage = 'A server error has occured. Unable to retrieve user information.';
    });

    Auth.isAdmin().then(isAdmin => {
      if (isAdmin) {
        $scope.clientId = $stateParams.clientId;
      }
    });

    $scope.closeModal = function () {
      $scope.showFlexIdModal = false;
      $scope.showClientDocumentList = false;
      $scope.showDownloadDialog = false;
      $scope.errorDialog = false;
      $scope.errorMessage = '';
    }

    $scope.closeDeleteModal = function () {
      $scope.fileContentToDelete = {};
      $scope.showDeleteConfirmModal = false;
    }
    $scope.showFlexId = function () {
      $scope.showFlexIdModal = true;
    }

    $scope.openDeleteFileConfirmModal = function (content) {
      $scope.showDeleteConfirmModal = true;
      $scope.fileContentToDelete = content;
    }

    $scope.fileContentToDelete = {};

    $scope.confirmDelete = function () {

      // call delete
      $scope.loadingMessage = "Deleting Client Document...";
      $scope.showDeleteConfirmModal = false;
      $scope.showSpinner = true;
      var deleteParam = {
        FileKey: $scope.fileContentToDelete.Key
      };

      DataM.deleteClientFile(deleteParam).then(function (data) {

        $scope.openClientDocTable();

      }).catch(function (err) {

        $scope.errorDialog = true;
        $scope.errorMessage = 'A server error has occured. Unable to delete file.';
      })
    }


    $scope.openClientDocTable = function () {

      $scope.loadingMessage = "Loading Document List...";

      $scope.showSpinner = true;
      $scope.showClientDocumentList = false;

      var clientData = {
        clientId: $scope.clientId
      }

      DataM.getClientFiles(clientData).then(function (data) {

        $scope.showClientDocumentList = true;
        $scope.showSpinner = false;
        $scope.fileContents = data.data.Contents;

      }).catch(function (err) {
        $scope.showSpinner = false;
        // show error
        $scope.errorDialog = true;
        $scope.errorMessage = 'A server error has occured. Unable to retrieve client files.';
      });

    }

    $scope.downloadFile = function (content) {

      $scope.showSpinner = true;
      $scope.loadingMessage = "Retrieving Document...";

      $scope.showClientDocumentList = false;
      var downloadParam = {
        FileKey: content.Key
      };

      // determine status
      DataM.downloadClientFile(downloadParam).then(function (data) {

        $scope.showSpinner = false;
        $scope.showDownloadDialog = true;

        // Download file if exists
       /* var hiddenElement = document.createElement('a');
        hiddenElement.href = 'api/aws/downloadFile?FileKey=' + content.Key
        hiddenElement.target = '_self';
        hiddenElement.download = content.FileName;
        hiddenElement.click();*/
        $scope.downloadFileContent(content);

      }).catch(function (err) {
        $scope.showSpinner = false;
        $scope.errorDialog = true;
        $scope.errorMessage = 'A server error has occured. Unable to download file.';

      });
    }

    $scope.downloadFileContent = function (content) {

      $http({
        url: '/api/aws/downloadFile?FileKey=' + content.Key,
        method: 'GET',
        responseType: 'arraybuffer'
      }).then(function (response) {
        var filename = content.FileName;

        var file = new Blob([response.data], {});

        //trick to download store a file having its URL
        var fileURL = URL.createObjectURL(file);
        var a = document.createElement('a');
        a.href = fileURL;
        a.target = '_blank';
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }, function (error) {
        console.log('DL Error', error);
      })

    }
  });

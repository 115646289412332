import { triggerGtagEvent } from "moorr-common";

import hasFlag from "../../../components/data/graphql/featureFlags";
import { FLAG_EXPENSE_NEW_CATEGORIES } from "../../../common/constants/flags";
import { defaultExpenses } from "../../../common/constants/expenses/clientStructure";
import { oldDefaultExpenses } from "../../../common/constants/expenses/clientStructureOld";

angular.module('meanApp')
  .controller('step1-controller', function ($scope, $rootScope, $state, $stateParams, $filter, DataM, $window, Auth, empowerDataService, $timeout, Idle, DataHelper, $document) {
    
    // Feature Flags ---------------------------------------
    $scope.queryFeatureFlag = function () {
      hasFlag(FLAG_EXPENSE_NEW_CATEGORIES).then(response => {
        $scope.isFlagActiveExpenseNewCategories = response

        $scope.defaultExpenses = $scope.isFlagActiveExpenseNewCategories
          ? defaultExpenses
          : oldDefaultExpenses;
      })
    }

    $scope.queryFeatureFlag()
    // -----------------------------------------------------

    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.loadingMessage = "Loading...";
    $scope.isFirstStep = true;
    $scope.empowerData = empowerDataService;
    $scope.timeAllocated = {};

    $scope.showClientSpinner = false;
    $scope.showErrorMessage = false;
    $scope.error = {};

    $scope.savedText = "Save";
    $scope.clientId = $stateParams.clientId;
    $scope.stepsState = {
      back: 'step1',
      next: 'step2',
    }
    $scope.defaultExpenses = [];

    $scope.incomes = [{}];

    $scope.addIncome = function () {
      $scope.incomes.push({});
    }

    $scope.infoReq = {
      bankStatements: null,
      creditCardStatements: null,
    };
    $scope.incomeTips = {};
    $scope.expensesTips = {};

    if (sessionStorage.getItem('incomeTips')) {
      $scope.incomeTips = JSON.parse(sessionStorage.getItem('incomeTips'));
    }
    if (sessionStorage.getItem('expenseTips')) {
      $scope.expensesTips = JSON.parse(sessionStorage.getItem('expensesTips'));
    }
    if (sessionStorage.getItem('infoReq')) {
      $scope.infoReq = JSON.parse(sessionStorage.getItem('infoReq'));
    }
    if (sessionStorage.getItem('defaultExpenses')) {
      $scope.defaultExpenses = JSON.parse(sessionStorage.getItem('defaultExpenses'));
    }
    if (sessionStorage.getItem('timeAllocated')) {
      $scope.timeAllocated = JSON.parse(sessionStorage.getItem('timeAllocated'));
    }

    $scope.next = function () {
      $scope.showClientSpinner = true;
      sessionStorage.setItem('incomeTips', JSON.stringify($scope.incomeTips));
      sessionStorage.setItem('expenseTips', JSON.stringify($scope.expensesTips));
      sessionStorage.setItem('infoReq', JSON.stringify($scope.infoReq));
      sessionStorage.setItem('defaultExpenses', JSON.stringify($scope.defaultExpenses));
      sessionStorage.setItem('timeAllocated', JSON.stringify($scope.timeAllocated));
      $scope.save();

      const USER_MS_ONBOARDING_STEP_1_COMPLETED = "onboarding_steps_completed";
      triggerGtagEvent(
        USER_MS_ONBOARDING_STEP_1_COMPLETED,
        {type: "moneysmartsStep1"},
        true
      )
    }

    // if admin add uid to get request
    var requestPayload = {};

   

    DataM.getClientSurvey(function (data) {
     
      if (data.clientSurvey && data.clientSurvey.hasOwnProperty('timeAllocated')) {
        $scope.timeAllocated = data.clientSurvey.timeAllocated;
      }

    });


    $scope.save = function (stay) {
      var formData = {
        'clientSurvey': {
          'topicsToDiscuss': {},
          'timeAllocated': $scope.timeAllocated,
          'quiz': {},
          'selfAssessment': {},
          'whatMoneyMeans': {},
          'findUs': '',
          'referrer': ''
        }
      };
      //SAVE TO DB
      DataM.saveClientSurvey(formData).then(function (data) {
        
        $scope.showClientSpinner = false;
        if (stay==null){
          $state.go('step2')}

          $scope.savedText = "Saved";  

      }).catch(function (err) {
        console.log('Save error', err);
        $scope.showClientSpinner = false;
        $scope.showErrorMessage = true;
        $scope.error.title = "Save Error";
        $scope.error.message = "There seems to be a problem with saving your data. Please try to refresh the page and try again. If error still occurs Please contact an Adviser from Empower Wealth.";

      });
      ;
    }

    $scope.closeError = function () {
      $scope.showErrorMessage = false;
    }
  });

'use strict';

angular.module('meanApp')
  .config(function ($stateProvider) {
    $stateProvider
      .state('lite-fact-find-v1', {
        url: '/litefactfind-v1',
        templateUrl: 'app/shortFactFind/short-factfind.html',
        controller: 'short-fact-find-controller',
        authenticate: 'user'
      })
      .state('lite-fact-find', {
        url: '/litefactfind',
        templateUrl: 'app/shortFactFind/short-factfind-v2.html',
        controller: 'short-fact-find-controller-v2',
        authenticate: 'user'
      })

      .state('short-fact-find', {
        url: '/short-fact-find/:clientId',
        params: {
          clientId: {
            value: null,
            squash: false
          }
        },
        templateUrl: 'app/shortFactFind/short-factfind.html',
        controller: 'short-fact-find-controller',
        authenticate: ['user','admin']
      });
      
  });

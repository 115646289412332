import { triggerGtagEvent } from "moorr-common";
import completeOnboarding from "../../components/data/moorr/completeOnboarding"

const fieldNames = {
  "personalInfo.client1.fName": "First Name",
  "personalInfo.client1.lName": "First Name",
  "personalInfo.client1.age": "Age",
  "personalInfo.client1.mPhone": "Mobile",
  "personalInfo.client1.state": "State",
  "personalInfo.client1.tenure": "Living Situation",
  "personalInfo.client1.dob": "Date of Birth",
  "personalInfo.client1.state": "State",
  "personalInfo.client2.fName": "Partner First Name",
  "personalInfo.client2.lName": "Partner Last Name",
  "personalInfo.client2.email": "Partner Email",
  "personalInfo.client2.age": "Partner Age",
  "personalInfo.client2.mPhone": "Partner Mobile",
  "personalInfo.client2.dob": "Partner Date of Birth",
  "topicsToDiscuss.others": "Other Topics",
  "clientNotes": "Tell us what you want to achive",
}

angular.module('meanApp')
  .controller('short-fact-find-controller-v2', function ($scope, $state, DataM, Auth, empowerDataService, $document) {
    DataM.getSummary().then(function (data) {
      const client = data.clientSummary;
      if(client.surveyComplete) {
        $state.go('dashboard')
      }
    });
    
    $scope.showClientSpinner = true;
    $scope.isAdmin = false;
    Auth.isAdmin().then(isAdmin => ($scope.isAdmin = isAdmin));

    $scope.empowerData = empowerDataService;
    $scope.showRequiredField = false;

    // Goals
    $scope.topicsToDiscuss = {};
    $scope.timeAllocated = {
      other: []
    };

    // Personal Info
    $scope.dependants = [];
    $scope.personalInfo = {};

    $scope.hasPartner = false;
    $scope.hasDependants = false;

    $scope.numberOfDependants = "0";
    $scope.setUpDependants = function(numString) {
      $scope.dependants.splice(0, $scope.dependants.length);
      for(var i=$scope.dependants.length; i<numString; i++){
        $scope.dependants.push({});
      }
    }

    $scope.ageChanged = function(client){
      client.ageEnteredOn = new Date();
    }

    $scope.clientNotes = "";

    $scope.addNewDependancy = function () {
      var dependant = {};
      $scope.dependants.push(dependant);
    }

    $scope.removeDependant = function (dependant) {
      $scope.dependants.splice($scope.dependants.indexOf(dependant), 1);
    }

    function formatClientData() {
      const topicsToDiscuss = { ...$scope.topicsToDiscuss };
      delete topicsToDiscuss.other;

      const timeAllocated = { ...$scope.timeAllocated };
      timeAllocated.other = timeAllocated.other.map((v) => ({ desc: v.desc, rank: v.rank }));

      const data = {
        page: "Lite Fact Find",
        personalInfo: {
          client1: {
            _id: $scope.client1._id || "__ObjectId__client1",
            fName: $scope.client1.fName,
            lName: $scope.client1.lName,
            mPhone: $scope.client1.mPhone,
            tenure: $scope.client1.tenure,
            residency: $scope.client1.residency,
            dob: $scope.client1.dob,
            address: {
              state: $scope.client1.address?.state
            }
          }
        },
        clientSurvey: {
          timeAllocated,
          topicsToDiscuss
        },
        clientNotes: $scope.clientNotes,
        surveyComplete: true,
        surveyCompletedAt: new Date(),
      }

      if ($scope.dependants?.length){
        data.personalInfo.dependants = $scope.dependants.map((v, i) => ({ ...v, _id: v._id || '__ObjectId__dependent'+i }));
      }

      if ($scope.client1.dob){
        data.personalInfo.client1.ageEnteredOn = new Date();
        data.personalInfo.client1.age = Math.floor((Date.now() - $scope.client1.dob.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
      }

      if ($scope.hasPartner) {
        data.personalInfo.client2 = {
          _id: $scope.client2._id || "__ObjectId__client2",
          fName: $scope.client2.fName,
          lName: $scope.client2.lName,
          mPhone: $scope.client2.mPhone,
          email: $scope.client2.email,
          dob: $scope.client2.dob,
        };

        if ($scope.client2.dob){
          data.personalInfo.client2.ageEnteredOn = new Date();
          data.personalInfo.client2.age = Math.floor((Date.now() - $scope.client2.dob.getTime()) / (365.25 * 24 * 60 * 60 * 1000));
        }
      }

      return data;
    }

    $scope.save = function () {
     $scope.showClientSpinner = true;

      // Format clientSchema here
      if(formIsValid() === false) {
        return false;
      }

      completeOnboarding(formatClientData()).then(function (data) {
        if (data.__errors || !data?.update?._id){
          console.log('Save error', data.__errors);
          $scope.didNextSave = false;
          $scope.showClientSpinner = false;
          $scope.showSaveError = true;
          $scope.saveErrors = [];

          data.__errors = data.__errors || [{ message: "Failed to save, please try again." }];
          data.__errors.forEach(er => {
            const errors = er?.extensions?.exception?.errors;
            if (errors){
              for (const id in errors){
                $scope.saveErrors.push({ field: (fieldNames[id] || id), message: errors[id].message });
              }
            } else {
              $scope.saveErrors.push(er);
            }
          });
          $scope.$digest();
        } else {
          const USER_MS_ONBOARDING_LITE_COMPLETED = "onboarding_completed";
          triggerGtagEvent(
            USER_MS_ONBOARDING_LITE_COMPLETED,
            {type: "lite"},
            true
          );

          $scope.showClientSpinner = false;
          $state.go('dashboard');
        }
      }).catch(function (err) {
          $scope.showClientSpinner = false;
          $scope.showSaveError = true;
          $scope.saveErrors = [{ message: "Error saving data, please try again." }];
          $scope.$digest();
      });
      
      // // SAVE TO DB
      // DataM.saveShortFactFind(formData).then(function (data) {
      //   $scope.showClientSpinner = false;
      //   $state.go('dashboard');
      // }).catch(function (err) {
      //   console.log('Save error',err);
      //   $scope.didNextSave = false;
      //   $scope.showClientSpinner = false;
      //   $scope.showSaveError = true;
      // });
    }


    DataM.getPersonalInfo().then(function (data) {
      $scope.showClientSpinner = false;
      $scope.householdClients = [];
      
      if (data.personalInfo.client1) {
        $scope.client1 = data.personalInfo.client1;
        if ($scope.client1.dob)
          $scope.client1.dob = new Date($scope.client1.dob);
      }
      if (data.personalInfo.client2) {
        $scope.client2 = data.personalInfo.client2 ;
        $scope.hasPartner = true;
      } else {
        $scope.client2 = {};
      }

      $scope.dependants = data.personalInfo.dependants;

    }).catch(function (err) {
      console.log('Get personal info error', err);
      $scope.showRetrieveError = true;
    });

    $scope.closeModal = function () {
      $scope.showRetrieveError = false;
      $scope.showSaveError = false;
      
    }

    function formIsValid(){
      if (!$scope.form.$valid) {
        $scope.showClientSpinner = false;
        $scope.showRequiredField = true;
        return false;
      } else {
        return true;
      }
    }

    $scope.closePopup = function(){
      if (!$scope.form.$valid &&  $scope.showRequiredField == true) {
        $scope.showRequiredField = false;
        if (!$scope.form.$valid) {
          if(angular.element($document[0].querySelector('input.ng-invalid'))[0]) {
            angular.element($document[0].querySelector('input.ng-invalid'))[0].focus();
          } else if(angular.element($document[0].querySelector('select.ng-invalid'))[0]){
            angular.element($document[0].querySelector('select.ng-invalid'))[0].focus();
          }
        }
      }
    }

    $scope.addNewFutureGoal = function () {
      var goal = {};
      if (!$scope.timeAllocated.other) {
        $scope.timeAllocated.other = [];
      }
      $scope.timeAllocated.other.push(goal);
    };

    $scope.deleteFutureGoal = function (goal) {
      $scope.timeAllocated.other.splice($scope.timeAllocated.other.indexOf(goal), 1);
    };
  });

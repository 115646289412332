import queryFlexDetails from '../../components/data/moorr/queryFlexDetails'
import hasFlag from '../../components/data/graphql/featureFlags';

"use strict";

angular.module('meanApp')
  .directive('flexDialog', function() {
    return {
      templateUrl: 'components/flex-submit/dialogs.html',
      restrict: 'E',
      controller: 'flex-submit-controller',
      scope: {
        clientId: '=',
        showFlexIdModal: '=',
        showConfirmFlexSendModal: '=',
        showFlexLogModal: '='
      }
    };
  });

angular
  .module("meanApp")
  .controller(
    "flex-submit-controller",
    function (
      $scope,
      Auth,
      DataM,
      $stateParams,
    ) {
      $scope.isAdmin = false;
      Auth.isAdmin().then((isAdmin) => ($scope.isAdmin = isAdmin));

      $scope.flexId1 = null;
      $scope.flexId2 = null;
      $scope.client1Name = null;
      $scope.client2Name = null;
      $scope.hasFlexPermission = false;
      $scope.showingFlexIdModal = false;

      // Flex
      $scope.flexResponseMessage = "";
      $scope.flexLoanWriter = {
        usernameToken: "",
        emailAddress: "",
        passwordText: "",
        description: "",
      };

      $scope.flexLogModal = false;
      $scope.flexLogSubmittedAt = "";
      $scope.xmlPayload = "";
      $scope.showEmailAddress = undefined;

      hasFlag("flex.emailAddress").then(isActive => {
        $scope.showEmailAddress = isActive;
      });

      $scope.closeModal = function () {
        $scope.showFlexIdModal = false;
        $scope.showingFlexIdModal = false;
        $scope.showConfirmFlexSendModal = false;
        $scope.showFlexAlertModal = false;
        $scope.showFlexSuccessModal = false;
      };

      $scope.$watch('showFlexIdModal', function(newValue, oldValue) {
        if (newValue === true && newValue !== oldValue){
          $scope.showSpinner = true;

          Auth.isAdmin().then((isAdmin) => {
            if (isAdmin){
              queryFlexDetails($stateParams.clientId).then(data => {
                $scope.flexId1 = data.personalInfo?.client1?.flexId;
                $scope.flexId2 = data.personalInfo?.client2?.flexId;
                $scope.client1Name = [data.personalInfo?.client1?.fName, data.personalInfo?.client1?.lName].filter(v => !!v).join(' ');
                $scope.client2Name = [data.personalInfo?.client2?.fName, data.personalInfo?.client2?.lName].filter(v => !!v).join(' ');
                $scope.$digest();
              });
            }
          });
        }
      });

      $scope.$watch('showFlexLogModal', function(newValue, oldValue) {
        if (newValue === true && newValue !== oldValue){
          $scope.showFlexSuccessModal = true;
          $scope.showFlexLogModal = false;
          $scope.retrieveFlexLog();
        }
      });

      $scope.retrieveFlexLog = function () {
        $scope.showSpinner = true;
        DataM.getFlexLog()
          .then(function (data) {
            if (data.success === false) {
              if (data.response) {
                if (data.response.includes("missingFields")) {
                  var requiredFields = JSON.parse(data.response);
                  $scope.flexResponseMessage =
                    getFlexResponseMessage(requiredFields);
                  $scope.requireUpdatePages = requiredFields.sidebarFields;
                } else {
                  $scope.flexResponseMessage = data.response;
                }
              } else {
                $scope.flexResponseMessage =
                  "Flex server error occured during push";
              }
              $scope.showFlexAlertModal = true;
            } else {
              $scope.showFlexSuccessModal = true;

              $scope.flexOpportunityID = data.response.FLEXId;
            }
            $scope.flexLogModal = true;
            $scope.flexLogSubmittedAt = data.dateSubmitted;
            $scope.showSpinner = false;
          })
          .catch(function (err) {
            console.log("Retrieve error", err);
            $scope.showSpinner = false;
            $scope.flexResponseMessage = "Unable to retrieve log";
            $scope.showFlexAlertModal = true;
          });
      };

      $scope.isSubmitFlexPending = false;
      $scope.submitFlex = function () {
        var clientId = $scope.clientId;
        $scope.xmlPayload = "";
        $scope.payloadDisplayed = false;
        $scope.flexLogModal = false;
        if (clientId !== null) {
           $scope.isSubmitFlexPending = true;
          $scope.showSpinner = true;
          DataM.pushFlex({
            userId: clientId,
            UsernameToken: $scope.flexLoanWriter.usernameToken,
            EmailAddress: $scope.flexLoanWriter.emailAddress,
            PasswordText: $scope.flexLoanWriter.passwordText,
            Description: $scope.flexLoanWriter.description,
          })
            .then(function (data) {
              $scope.toJSON = "";
              $scope.showSpinner = false;
              $scope.isSubmitFlexPending = false;
              if (data.error) {
                $scope.showFlexAlertModal = true;
                $scope.flexResponseMessage = getFlexResponseMessage(data.error);
              } else if (!data.error && data.outboundMsg.Opportunity) {
                if (data.outboundMsg.Opportunity.FLEXId) {
                  $scope.showFlexSuccessModal = true;
                  $scope.flexOpportunityID =
                    data.outboundMsg.Opportunity.FLEXId;
                  $scope.xmlPayload = data.inboundMsg;
                  // reset the input form
                  $scope.flexLoanWriter = {
                    usernameToken: "",
                    emailAddress: "",
                    passwordText: "",
                    description: "",
                  };
                }
              } else {
                $scope.xmlPayload = data.inboundMsg;

                $scope.showFlexAlertModal = true;
                $scope.flexResponseMessage = getFlexResponseMessage(
                  data.outboundMsg
                );
              }
            })
            .catch(function (err) {
              console.log("FLEX Error", err);
              $scope.isSubmitFlexPending = false;
              $scope.showSpinner = false;
              $scope.showFlexAlertModal = true;
              if (err.status !== 403) {
                $scope.flexResponseMessage = "Flex Data Mapping error occured";
              } else {
                $scope.flexResponseMessage = "User not allowed to push in Flex";
              }
            });
        }
      };

      function getFlexResponseMessage(flexResponse) {
        var responseMessage = "";
        try {
          if (flexResponse.missingFields) {
            responseMessage = "Fields Required: \n";
            responseMessage += flexResponse.missingFields.join("\n");
          } else if (flexResponse.headers) {
            if (flexResponse.headers["siebel-error-message-1"]) {
              responseMessage = flexResponse.headers["siebel-error-message-1"];
            } else {
              if (flexResponse.body) {
                if (
                  flexResponse.body["SOAP-ENV:Envelope"] &&
                  flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"] &&
                  flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0]
                ) {
                  if (
                    flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0][
                      "SOAP-ENV:Fault"
                    ] &&
                    flexResponse.body["SOAP-ENV:Envelope"]["SOAP-ENV:Body"][0][
                      "SOAP-ENV:Fault"
                    ][0]["faultstring"]
                  ) {
                    responseMessage =
                      flexResponse.body["SOAP-ENV:Envelope"][
                        "SOAP-ENV:Body"
                      ][0]["SOAP-ENV:Fault"][0]["faultstring"];
                  } else {
                    responseMessage =
                      "Server error occured during flex push. Please try again later.";
                  }
                } else {
                  responseMessage =
                    "Server error occured during flex push. Please try again later.";
                }
              }
            }
          } else {
            responseMessage =
              "Server error occured during flex push. Please try again later.";
          }
          return responseMessage;
        } catch (err) {
          console.log("Flex response error");
          var responseMessage =
            "Server error occured during flex push. Please try again later.";
          return responseMessage;
        }
      }
    }
  );